<template>
  <!-- <div style="width: 100%;"> -->
    <video ref="videoPlayer" class="video-js" playsinline></video>
  <!-- </div> -->
</template>

<script>
import videojs from 'video.js';
import 'video.js/dist/video-js.css';

export default {
  name: 'VideoPlayer',
  props: {
    options: {
      type: Object,
      default() {
        return {};
      }
    },
    setIndex: {
      type: String,
      default: ''
    }
  },
  data() {
    return {
      player: null
    }
  },
  mounted() {
    const vm = this;
    this.player = videojs(this.$refs.videoPlayer, this.options, () => {
      // this.player.log('onPlayerReady', this);
    });
    this.player.on('play', function() {
      vm.$emit('setVideowWatermark', vm.setIndex);
    });
  },
  beforeUnmount() {
    if (this.player) {
      this.player.dispose();
    }
  }
}
</script>